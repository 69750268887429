<template>
  <div class="shopMember" @click="num=0">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/check' }">结算管理</el-breadcrumb-item>
      <el-breadcrumb-item>结算列表</el-breadcrumb-item>
    </el-breadcrumb>
      <div class="btn-list">
      <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0"
          class="btn-item"
          @click="chainGoodExport"
        >
          <span>导出</span>
          <i class="iconfont icon-daochu" style="font-size:12px"></i>
        </el-button>
      </div>
      <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width:100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="shop_name" label="门店名称"> </el-table-column>
          <el-table-column prop="settlement_time" label="结算时间">  </el-table-column>
          <el-table-column prop="order_amount" label="总订单金额" v-if="is_supermarket!=1"> </el-table-column>
          <el-table-column prop="total_goods_price" label="商品总价" v-if="is_supermarket==1"> </el-table-column>
          <el-table-column prop="total_good_discount_price" label="约定折扣价" v-if="is_supermarket==1"> </el-table-column>
          <el-table-column prop="total_settle_price" label="商品结算金额" v-if="is_supermarket==1"> </el-table-column>
          <el-table-column prop="platform_amount" label="平台服务费" v-if="is_supermarket!=1"> </el-table-column>
          <el-table-column prop="platform_amount" label="平台服务费" v-if="is_supermarket==1"> </el-table-column>
          <el-table-column prop="packing_fee" label="打包费" v-if="is_supermarket==1"> </el-table-column>
          <el-table-column prop="last_amount" label="已结退款金额"> </el-table-column>
          <el-table-column prop="settle_amount" label="总结算金额" v-if="is_supermarket!=1"> </el-table-column>
          <el-table-column prop="total_total_settle_price" label="总结算金额" v-if="is_supermarket==1"> </el-table-column>
          <el-table-column prop="bank_name" label="收款人户名">  </el-table-column>
          <el-table-column prop="bank_number" label="收款人账号">  </el-table-column>
          <el-table-column prop="bank_cnaps" label="收款人开户行（CNAS号）">  </el-table-column>
          <el-table-column prop="bill_status" label="打款状态">
            <template slot-scope="scope">
              <span  :style="{'color':scope.row.payment_status==0?'#CD0550':''}">
                {{scope.row.payment_status==0?'未打款':'已打款'}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="payment_time" label="打款时间"></el-table-column>
          <el-table-column prop="bill_status_str" label="发票状态"> </el-table-column>
          <el-table-column prop="created_at" label="创建时间"> </el-table-column>
          <!-- <el-table-column label="操作">
            <template slot-scope="scope">
              <div style="position: relative; display: inline" v-if="scope.row.payment_status!=0&&scope.row.bill_status==0">
                <i
                  style="color: #3ca6f0"
                  @click.stop="operation(scope.row)"
                  class="iconfont icon-bianji"
                >
                </i>
                <div v-if="scope.row.num == num" class="operation">
                  <p v-if="scope.row.payment_status==1&&scope.row.bill_status==0" @click.stop="apply(scope.row.id)" class="active">
                    发票申请
                  </p> -->
                  <!-- <a class="downLoad active" v-if="scope.row.payment_status==1&&scope.row.bill_status==2" :href="imgUrl+scope.row.image">
                    发票下载
                  </a> -->
                <!-- </div>
              </div>
            </template>
          </el-table-column> -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div style="position: relative; display: inline">
                <i
                  style="color: #3ca6f0"
                  @click.stop="operation(scope.row)"
                  class="iconfont icon-bianji"
                >
                </i>
                <div v-if="scope.row.num == num" class="operation">
                  <p v-if="scope.row.payment_status==1&&scope.row.bill_status==0" @click.stop="apply(scope.row.id)" class="active">
                    发票申请
                  </p>
                  <p v-if="true" @click.stop="seeGoods(scope.row)" class="active">
                    商品列表
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-num">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="prev,pager,next, total,sizes,jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
import {billApply_api,VgetSettleList_api,DgetSettleList_api} from "@/api/shop.js"
import {imgUrl,httpUrl} from "@/utils/http.js"
import local from "@/utils/local.js"
import axios from "axios"
export default {
  data() {
    return {
      currentPage: 1,
      pageSizes: [ 10, 20,50],
      pageSize: 10,
      total: 0,
      tableData:[],
      num:0,
      active:1,
      imgUrl:imgUrl,
      filename:'结算记录',
      is_supermarket:'',
      isChain:''
    }
  },
  methods: {
    //重置
    emptySearch() {
      this.memberForm = {}
      this.getSettleList()
    },
    //商品列表
    seeGoods(row){
      local.set("orderNum1",JSON.stringify(row))
      this.$router.push("/check/outGoods")
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize=e
      this.getSettleList()
    },
     //切换页
    handleCurrentChange(e) {
      this.currentPage=e
      this.getSettleList()
    },
    //显示操作页面
    operation(row) {
      if(this.num==row.num){
        this.num=0
      }else{
        this.num=row.num
      }
    },
    //获取结算列表
    async getSettleList(){
      //isChain判断是连锁还是单门店
      const IsSupermarket=this.isChain==1?VgetSettleList_api:DgetSettleList_api
      let res=await IsSupermarket({
        page:this.currentPage,
        limit:this.pageSize
      })
      this.tableData=res.data.data.list
      this.tableData.forEach((item,index)=>{
        item.num=index+1
      })
      this.total=res.data.data.total_count
    },
    //申请发票
    async apply(id){
      let res=await billApply_api({
        settle_id:id
      })
      if(res.data.code==0){
         this.$message({
          message: "发票申请成功!",
          type: "success"
        })
        this.getSettleList()
      }
    },
    //导出
    chainGoodExport() {
        const loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
          //is_supermarket判断是否是超市     //isChain判断是连锁还是单门店
      const IsSupermarket=this.is_supermarket==1?(this.isChain==1?'api/v2/settle/export':'api/v2/store/settle/export'):'api/v1/settle/export'
      axios
        .get(
          `${httpUrl}${IsSupermarket}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!');
          loading.close()
        })
    }
  },
  created(){
    this.is_supermarket=local.get("is_supermarket")
    this.isChain = local.get("user");
    this.getSettleList()
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow-x: scroll;
}
/deep/.el-table__header-wrapper{
  overflow: inherit;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
  a{
    width: 85px;
    height: 40px;
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
    display: inline-block;
     text-decoration : none 
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
.btn-list{
 background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
</style>